import Head from "next/head";
import Link from "next/link";
import getHeaderLayout from "layouts/getHeaderLayout";
import clsx from "clsx";

const styles = {
    bodyContainer: clsx(
        "container",
        "mx-auto",
        "mt-12",
        "px-2",
        "grid",
        "grid-cols-1",
        "md:grid-cols-2",
        "lg:grid-cols-3",
        "auto-rows-[20]",
        "gap-4",
    ),
    card: clsx(
        "flex",
        "justify-center",
        "items-center",
        "cursor-pointer",
        "bg-white",
        "py-4",
        "shadow-[2px_2px_4px_2px_#eee]",
        "[&:hover]:shadow-[2px_2px_8px_4px_#eee]",
    ),
};

type Route = { label: string; href: string };

const routes: Route[] = [
    { label: "Courtsite Superuser", href: "/superuser/promocode" },
    { label: "Settlement", href: "/settlement" },
    { label: "Customer Support", href: "/support/paymentsessions" },
];

const Home = (): JSX.Element => {
    return (
        <>
            <Head>
                <title>Courtsite Setiausaha</title>
            </Head>
            <div className={styles.bodyContainer}>
                {routes.map((route) => (
                    <Link
                        className={styles.card}
                        key={route.label}
                        href={route.href}
                        passHref
                    >
                        {route.label}
                    </Link>
                ))}
            </div>
        </>
    );
};

Home.getLayout = getHeaderLayout;
Home.isPrivate = true;

export default Home;
