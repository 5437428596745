import { ReactNode } from "react";

const Header = ({
    children,
    logoutButton,
}: {
    children?: ReactNode;
    logoutButton?: ReactNode;
}): JSX.Element => {
    return (
        <div className="sticky top-0 z-10 flex h-full flex-col justify-center bg-primary-100 px-2 py-4 shadow-lg">
            <div className="flex justify-between">
                <div>{children}</div>
                <div className="flex justify-end">{logoutButton}</div>
            </div>
        </div>
    );
};

export default Header;
