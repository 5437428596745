import { ReactElement, ReactNode } from "react";
import Header from "layouts/Header";
import { Button, Spin } from "components";
import { useAuth } from "lib/firebase/hooks";

const getHeaderLayout = (page: ReactElement): JSX.Element => {
    return <Page>{page}</Page>;
};

const Page = ({ children }: { children?: ReactNode }): JSX.Element => {
    const { isInitialised, user, signout } = useAuth();

    const handleSignoutButtonClick = (): void => {
        if (signout) {
            signout();
        }
    };

    const isLoggedIn = isInitialised && !!user;
    const logoutButton = (
        <>
            {!isInitialised && <Spin />}
            {isLoggedIn && (
                <Button onClick={handleSignoutButtonClick}>Log out</Button>
            )}
        </>
    );

    return (
        <div>
            <Header logoutButton={logoutButton} />
            <div>{children}</div>
        </div>
    );
};

export default getHeaderLayout;
